import {ICustomer} from "../booking/booking.types";
import {
  IScheduleService,
  ISimpleBookingOption,
  servicePaymentType,
  IBookingTag
} from "shared-types/index";

export interface IWidgetSettings {
  maximumPeopleMessage: string;
  dayClosedMessage: string;
  serviceClosedMessage: string;
  enquiryMessage: string;
  cancelledBookingMessage: string;
  noTablesAvailableMessage: string;
  timeNoLongerAvailableMessage: string;
  showTimeNotAvailable: boolean;
  timeNotAvailableMessage: string;
  maxPeoplePerBooking: number;
  canCustomersChooseSection: boolean;
  termsAndConditionsMessage: string;
  publicTermsAndConditionsMessage?: string;
  preAuthorisationMessage: string;
  bookingCancellationWindow: string; // number as string
  preAuthReleasingWindow?: string;
  theme: unknown;
  font?: string;
  accentColour: unknown;
  button: unknown;
  allowOnlineBookings: boolean;
  onlineBookingsOffMessage: string;
  minMinutesBeforeServiceBooking: number;
  maxDaysInFutureBooking: number;
  tooFarInAdvanceMessage: string;
  disableEditingTimeWindow: number;
  finalWidgetScreenMessage: string;
  minMinutesBeforeBooking: number;
  standByListDaysWeekDays?: string[];
  standByList?: boolean;
  standbyConfirmationTimeoutInMinutes?: number;
  enableNextAvailableBooking?: boolean;
  disableCancelTimeWindowInHours: number;
  enableNoTableMessage: boolean;
  enableRobotValidation: boolean;
  enableAdvancedEditingSupport: boolean;
  enableCompleteBookingRobotValidation?: boolean;
  enableCancelRobotValidation?: boolean;
  enableEditRobotValidation?: boolean;
  enableConfirmBookingRobotValidation?: boolean;
  titleSubscription?: string;
  defaultEmailSubscribedForWidget?: boolean;
  titleTermsAndCondition?: string;
  privacyPolicyURL?: string;
  enableBookedByOnWidget?: boolean;
}

export interface IEwayInfo {
  acceptVisa: boolean;
  acceptMasterCard: boolean;
  acceptAmericanExpress: boolean;
  acceptDiners: boolean;
  acceptJcb: boolean;
  clientSideEncryptionKey?: string;
  paymentProvider: paymentProviderType; // will get set to paymentProviderType.eway in AccountService -> getPaymentSettings
}

export interface IStripeInfo {
  publishableKey: string;
  paymentProvider: paymentProviderType; // will get set to paymentProviderType.stripe in AccountService -> getPaymentSettings
  stripe3DEnabled: boolean;
}


export enum paymentProviderType {
  eway = 'eway',
  stripe = 'stripe'
}

/**
 * Annoyingly this is uppercase first letter when coming from IOwnedVenue.
 * Gets forced to lowercase in AccountService -> getPaymentSettings
 */
export enum paymentProviderTypeUppercase {
  Eway = 'Eway',
  Stripe = 'Stripe'
}

export interface IPaymentSettings {
  paymentProvider: paymentProviderTypeUppercase;
  eway: IEwayInfo;
  stripe: IStripeInfo;
  preAuthReleasingWindow: number;
}

export interface IAnalyticsItem {
  apiKey: string;
  gtmFields?: string[];
}

export interface IAnalyticsInfo {
  facebook: IAnalyticsItem;
  google: IAnalyticsItem;
}

// very similar to IVenue, but not identical
export interface IOwnedVenue {
  id: number;
  name: string;
  country: string;
  address: string;
  suburb: string;
  postcode: string;
  state: string;
  phone: string;
  // city: string; // @toDo check this actually exists on ownedVenue. severity: high
  url: string;
  active: boolean;
  logoUrl: string;
  alternatePhone: string;
  contactEmail: string;
  widgetSettings: IWidgetSettings;
  timeZoneId: string;
  currency: string; // USD, AUD, GBP, etc
  paymentSettings: IPaymentSettings;
  canCustomersCancelBookings: boolean;
  canCustomersEditBookings: boolean;
  canCancelBookingBySms: boolean;
  canEditBookingBySms: boolean;
  analytics: IAnalyticsInfo;
  additionalBookingRequirements?: IAdditionalBookingRequirements;
  groupAvailabilityActive?: boolean;
  groupAvailabilitySubscriptionActive?: boolean;
  groupAvailabilityThreshold?: number;
}

export interface IScheduleParams {
  date: string; // YYYY-M-D
  numofpeople: number;
  bookingId?: string;
  isLookForward?: boolean;
}

export interface ISchedule {
  blockoutMessage: string;
  isVenueOpen: boolean;
  services: IScheduleService[];
  tags: IBookingTag[];
  currentBookingsOnStandbyList: number;
  maxBookingsOnStandbyList: number;
  venueId?: number;
  displayOrder?: number;
}

export interface INabSearchResponse {
  isTimeOut: boolean;
  schedule: ISchedule;
  lastSearchDate: string;
  isLookForward: boolean;
}


export interface IPaymentType {
  amount: number, // 132.5,
  paymentTypeName: servicePaymentType // "FullPayment"
  amountDue: number;
  discountAmount: number;
}

export interface IMenuOptionsResponse {
  statusText: string; // OK
  status: number; // 200
  data: ISimpleBookingOption[];
}


export interface ISectionState {
  id: string; // "section_GIYOEZ9CC963K"
  sectionState: boolean;
  isSectionBlocked: boolean;
}

export interface IGetBookingParams {
  token: string;
}


export interface IStandbyRequest {
  covers: number;
  serviceId: string;
  sectionId: string;
  desiredBookingTime: string;
  isFlexibleTime?: boolean;
  tags: IBookingTag[];
  customer: ICustomer;
  isCancelPreviousSB?: boolean;
  token?: string;
}

export interface IAdditionalBookingRequirements {
  requiresVerification: boolean;
  verificationHeader: string;
  verificationMessage: string;
}
